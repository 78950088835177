<template>
  <div class="relative home">
    <header class="py-4 bg-white">
      <div class="flex flex-col items-center justify-between w-full max-w-screen-lg px-4 mx-auto sm:flex-row">
        <div class="mb-4 logo md:mb-0">
          <img src="images/logo.jpg"/>
        </div>
        <div class="flex flex-col items-center text-center">
          <p class="mb-1 text-xs text-gray-800 lg:text-sm">Call us for an appointment</p>
          <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z" fill="rgba(60,90,30,1)"/></svg>
            <p class="font-semibold lg:text-xl text-siteDGreen"><a href="tel:+6597319253">(65) 9731 9253</a></p>
          </div>
        </div>
      </div>
    </header>
    <section class="relative py-16 overflow-hidden bg-gray-200 bg-center bg-cover bg-header-mobile lg:py-24">
      <div class="absolute top-0 right-0 z-10 hidden w-3/5 h-full bg-center bg-no-repeat bg-contain opacity-50 lg:block bg-header xl:opacity-100"></div>
      <div class="relative z-30 flex items-end justify-between w-full max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap w-full py-12 overflow-hidden text-center lg:text-left lg:py-24">
          <div class="w-full overflow-hidden text-gray-800 lg:w-3/5">
            <h2 class="mb-2 text-4xl font-semibold leading-none uppercase text-siteDGreen lg:text-6xl lg:font-medium">A Legal Firm Specializes in Criminal <br class="hidden lg:block">& Civil Litigation</h2>
            <h4 class="text-2xl lg:text-4xl">Practice with Passion</h4>
          </div>
          <div class="w-full overflow-hidden lg:w-2/5">
            <!-- Column Content -->
          </div>
        </div>
      </div>
    </section>
    <section class="relative py-12 text-center text-gray-800 lg:pb-16 bg-siteDGreen">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
        <div class="relative z-50 px-4 py-8 -mt-24 bg-white shadow-2xl lg:p-8 enquiry-form">
          <h3 class="mb-4 text-2xl font-semibold leading-tight uppercase lg:mb-6 lg:text-4xl">Contact Us Today for Us To Better Assist You</h3>
          <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true" style="min-height:300px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5981121.html"><p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p></iframe><!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </section>
    <section class="relative py-12 text-center text-gray-800 lg:py-16">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
        <h4 class="mb-1 text-2xl font-semibold leading-none uppercase">About</h4>
        <h5 class="mb-8 text-3xl font-semibold leading-tight uppercase lg:text-4xl lg:mb-12">Nazir Khan Law Chambers</h5>
        <div class="flex flex-wrap items-center -mx-4 overflow-hidden">
          <div class="w-full px-4 overflow-hidden md:w-1/3 lg:w-1/3">
            <img class="mb-8 rounded" src="images/image-01.jpg">
          </div>
          <div class="w-full px-4 overflow-hidden text-left md:w-2/3 lg:w-2/3">
            <p class="mb-6">Nazir Khan Law Chambers is a dedicated legal firm that aims to act in the best interest of clients. Helmed by Nazir Khan, who has many years of business & legal practice, the firm specializes in civil, corporate, commercial & criminal litigation, bankruptcy & probate cases and landlord/tenant disputes, etc.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="py-12 text-center text-white bg-siteDGreen lg:py-16">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
        <h4 class="text-2xl font-semibold leading-none uppercase">Area of</h4>
        <h3 class="mb-4 text-3xl font-semibold leading-tight uppercase lg:text-4xl lg:mb-8">Practice</h3>
        <p class="mx-auto mb-8 lg:w-1/2">All cases will be carefully handled to achieve the most desired outcome. Strong belief in working closely with clients.</p>
        <div class="p-8 text-gray-800 bg-white">
          <div class="flex flex-wrap items-start -mx-4 overflow-hidden">
            <div class="w-full px-4 overflow-hidden text-left md:w-2/3 lg:w-1/3">
              <ul class="pl-5 list-disc">
                <li class="mb-1">Civil Litigation</li>
                <li class="mb-1">Corporate & Commercial Litigation</li>
              </ul>
            </div>
            <div class="w-full px-4 overflow-hidden text-left md:w-2/3 lg:w-1/3">
              <ul class="pl-5 list-disc">
                <li class="mb-1">Criminal Law & Litigation</li>
                <li class="mb-1">Bankruptcy</li>
              </ul>
            </div>
            <div class="w-full px-4 overflow-hidden text-left md:w-2/3 lg:w-1/3">
              <ul class="pl-5 list-disc">
                <li class="mb-1">Probate & Mental Capacity</li>
                <li class="mb-1">Landlord & Tenant Disputes</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-12 lg:py-16">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
        <h3 class="mb-8 text-3xl font-semibold leading-tight text-center text-gray-800 uppercase lg:text-4xl">Testimonials</h3>
        <carousel :per-page="1" :navigate-to="someLocalProperty" :mouse-drag="false" pagination-padding="10" pagination-active-color="#3c5a1e">
          <slide class="text-center">
            <h4 class="mb-4 font-bold">Matter: Stolen property (Abetment), theft</h4>
            <p class="mb-4"><i>“Dear Mr Nazir Khan, thank you very much for being my defence lawyer on my complicated legal case. It’s very difficult to get a good lawyer who really <b>care for your case and acted very professionally an took many hours to study my case.</b> I will definitely recommend Mr Khan giving him 5 stars for his hard amazing professional job as a good honest lawyer. Thank you Mr Khan.”</i></p>
            <p><b>- Mr. J.S.</b></p>
          </slide>
          <slide class="text-center">
            <h4 class="mb-4 font-bold">Matter: Breach of contract / bankruptcy / probate</h4>
            <p class="mb-4"><i>“Dear Mr Nazir Khan, please allow me to show my appreciation in the way you <b>professionally handled</b> my breach of contract, bankruptcy and my mother’s probate matters. You took great interest and time to advice me on the progress of the cases and outcome. I will strongly recommend you to my friends and others who require a good lawyer.”</i></p>
            <p><b>- Mr. B.K.</b></p>
          </slide>
          <slide class="text-center">
            <h4 class="mb-4 font-bold">Matter: Landlord & Tenant (shop)</h4>
            <p class="mb-4"><i>“My wife and I came across lawyer Nazir back in 2016 by chance, we had an issue with our landlord back in 2016 and after lawyer Nazir took up our case and assisted us on it. It took months before the issue was resolved in 2017, we had back our fair compensation. Nazir is a very experienced lawyer and <b>he is very meticulous and detailed in handling our case.</b> Thanks to Nazir for his expertise.”</i></p>
            <p><b>- Mr. & Mrs. A.W.</b></p>
          </slide>
          <slide class="text-center">
            <h4 class="mb-4 font-bold">Matter: Misrepresentation</h4>
            <p class="mb-4"><i>“Dear Mr Khan, thanks for your help in addressing on my concerns. I am truly appreciative of your <b>efforts and professionalism</b> that you have render me and I will definitely spread positive word of mouth to my friends and relatives in the event that they need your services”.</i></p>
            <p><b>- Mr. M.H</b></p>
          </slide>
          <slide class="text-center">
            <h4 class="mb-4 font-bold">Matter: Divorce</h4>
            <p class="mb-4"><i>“Mr Khan listened very attentively to my case, analyzed it from all points of view, and was able to advise me appropriately, went through the details of the procedures and even to the details of what I should expect of the reactions from the defendant and plaintiff (myself). As the situation warrant, Mr Khan was <b>willing to go the extra mile</b> to speak to the defendant to have a better understanding of my case and also to ensure we are heading towards the right direction for my case. With his good speaking skills and interpersonal skills, he was <b>quick to identify the issues</b> and quick to respond in order to ensure we are heading the right direction for my case. I am deeply grateful of Mr Khan’s earnest service, time, support and efforts in handling my case. He is dedicated, experienced, reliable and effective lawyer.”</i></p>
            <p><b>- Ms. I.W.</b></p>
          </slide>
          <slide class="text-center">
            <h4 class="mb-4 font-bold">Matter: Breach of Contract</h4>
            <p class="mb-4"><i>“Mr Khan is very patient, kind and very encouraging. He is very knowledgeable and ls always willing to answer any questions and give proper advice when needed. I am very thankful for him for taking up my case and updating me whenever needed. Mr Khan was very dedicated and was always thinking of how to help me throughout the case. I am very grateful for his help and I am glad that I had managed to find such a wonderful and dedicated lawyer.<br><br>Thank you, Mr Khan, for your help! I am truly grateful and very glad that I had met you. Thank you!”</i></p>
            <p><b>- Ms. A.W.</b></p>
          </slide>
          <slide class="text-center">
            <h4 class="mb-4 font-bold">Matter: Alleged Breached of Contract</h4>
            <p class="mb-4"><i>“I would like to express my heartfelt gratitude and appreciation to Mr. Khan for his unwavering support and dedication. His commitment to reviewing my documents thoroughly and meticulously have been invaluable to me. His attention to detail has ensured that everything is in perfect order. What sets him apart is the fact that he goes above and beyond his call of duty by extending his support beyond office hours. His willingness to help and make sure everything is in order is truly commendable.”</i></p>
            <p><b>- Ms. MTB</b></p>
          </slide>
        </carousel>
      </div>
    </section>
    <section class="relative py-12 pt-0 text-white bg-siteDGreen lg:py-16">
      <div class="relative top-0 mb-12 bg-center bg-cover lg:mb-0 bg-01 lg:absolute lg:w-1/2 lg:h-full"></div>
      <div class="w-full max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 overflow-hidden lg:w-1/2">
            <!-- Column Content -->
          </div>
          <div class="w-full px-4 overflow-hidden lg:w-2/5">
            <h3 class="mb-1 text-3xl font-semibold leading-tight text-center uppercase lg:text-4xl lg:text-left">Get One Step Closer <br>to Hear from Us</h3>
            <p class="mb-8 font-medium lg:text-lg">Call Us at <a href="tel:+6597319253">(65) 9731 9253</a> to Speak with Us Today!</p>
            <div class="enquiry-form">
              <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true" style="min-height:300px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5981121.html"><p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p></iframe><!-- feedback.activamedia.com.sg script ends here -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="py-12 text-center text-gray-800 lg:py-16">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
        <h1 class="logo">
          <h5 class="text-xl font-semibold uppercase">Nazir Khan Law Chambers</h5>
          <p>350 Orchard Road #11-08</p>
          <p class="mb-6">Shaw House</p>
          <p class="mb-6">
            <span class="font-semibold">Telephone:</span> <a href="tel:+6597319253" class="text-siteBlue">+65 9731 9253</a>
          </p>
          <p>
            (Strictly by Appointments Only)
          </p>
        </h1>
      </div>
    </footer>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d997.1956101828437!2d103.83098052710996!3d1.3056449990477175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da198da089053f%3A0x38305033482d9672!2sShaw%20House!5e0!3m2!1sen!2sph!4v1629701471017!5m2!1sen!2sph" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
</template>

<style>
  .bg-header-mobile {
    background-image: url(/images/bg-header-mobile.png);
  }
  .bg-header {
    background-image: url(/images/bg-header.png);
  }
  .bg-01, .bg-02 {
    min-height: 400px;
  }
  .bg-01 {
    background-image: url(/images/bg-01.jpg);
  }
  .bg-01 {
    background-image: url(/images/bg-02.jpg);
  }
  .icon-solution, .icon-services  {
    width: 60px;
  }
  .circles div {
    width: 140px;
    height: 140px;
  }
  .enquiry-form iframe {
    height: 430px !important;
  }
  @media (min-width: 640px) {
    .icon-solution, .icon-services   {
      width: 80px;
    }
    .circles div {
      width: 160px;
      height: 160px;
    }
  }
  @media (min-width: 1024px) {
    .bg-header-mobile {
      background-image: url(/images/bg-header.jpg);
    }
    .bg-01, .bg-02 {
      left: -16px;
    }
  }
</style>

<script>
  import { Carousel, Slide } from 'vue-carousel'
  import { CollapseTransition } from 'vue2-transitions'
  export default {
    components: {
      CollapseTransition,
      Carousel,
      Slide
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>
